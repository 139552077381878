.ledger-form[data-v-3fcfaa94] {
  width: 100%;
  height: 100%;
}
.ledger-form[data-v-3fcfaa94] .el-tabs {
  width: 100%;
  height: 100%;
}
.ledger-form[data-v-3fcfaa94] .el-tabs__header.is-left {
  min-width: 130px !important;
}
.ledger-form[data-v-3fcfaa94] .el-tabs--border-card {
  background-color: #f5f7fa;
}
.ledger-form[data-v-3fcfaa94] .el-tabs__content {
  height: 100%;
  overflow-y: auto;
}