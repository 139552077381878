.lineStatus-box[data-v-2e0410ac] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
}
.lineStatus-box .lineStatus-normal[data-v-2e0410ac] {
  border: 1px solid #67c23a;
  color: #67c23a;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
.lineStatus-box .lineStatus-error[data-v-2e0410ac] {
  border: 1px solid rgb(197, 33, 27);
  color: rgb(197, 33, 27);
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
.taskStatusPend[data-v-2e0410ac] {
  color: rgb(197, 33, 27);
}