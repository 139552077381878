.baseInfo[data-v-713afcfc] {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.formContentBox[data-v-713afcfc] {
  padding: 12px 0;
  height: calc(100% - 76px);
}
.formMain[data-v-713afcfc] {
  width: 100%;
  height: 100%;
}